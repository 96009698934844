body{
 background-color: #e1eedd;   
 color: black;
}

.container2{
    max-width: 1300px;
    width: 100%;
    margin: auto;
    padding: 0px 20px;
}

.events{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upcoming{
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    padding: 2rem;
    /* border: solid red; */
}

.cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card{
   flex: 0 0 calc(33.33% - 20px);
   max-width: calc(33.33% - 20px);
   width: 100%; 
   background: white;
   border-radius: 10px;
   padding: 20px;
   margin-bottom: 20px;
   transition: .3s;
   border: 2px solid transparent;
   color: black;
}

.card:hover{
    border-color: green;
}

.btn{
    border: none;
    outline: none;
    background-color: green;
    color: white;
    border-radius: 10px;
    max-width: 120px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid green;

}

.btn:hover{
    background: transparent;
    color: rgb(8, 80, 8);
}

@media(max-width: 969px){
    section{
        padding: 20px, 0px;
    }
    h1{
        font-size: 30px;
        line-height: 40px;
    }

    .card{
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
    }
}

@media(max-width: 600px){
    .card{
        flex: 0 0 100%;
        max-width: 100%;
    }
}