.container.contact_container{
    width:58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
    
}

#contact{
    background-color: #fff;
    width: 100%;
    opacity: 0.7;
}
 
::placeholder{
    color: white;
    font-weight: 1000;
}

.contact_options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact_option{
    background: white;
    padding:1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact_option:hover{
    background: transparent;
    border-color: green;
}

.contact_option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact_option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}


form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid green;
    resize: none;
    color: white;
}

@media screen and (max-width: 1024px) {
    .container.contact_container {
      /* grid-template-columns: 1fr;
      gap: 2rem; */
    }
}
  
   @media screen and (max-width: 600px) {
    .container.contact_container {
      width: var(--container-width-sm);
    }
}  

.payment{
    width: 20%;
    font-size: 1rem;
    height: 10vh;
    margin-left: 70%;
}