.featured{
position: absolute;
left: 0;
right: 0;
bottom: 1;
margin-bottom: 1rem;
margin-top: -15rem;
height: 30vh;
width: 100%;
background-color: #183a1d;
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-gap: 30px;
place-items: center;
color: #e1eedd;
/* line-height: 20px; */
}

.feat-btn{
  border-radius: 5px;
  padding: 17px 30px;
  border: none;
  color: #183a1d;
  cursor: pointer;
  font-weight: bold;
}
.feat-btn a:hover{
  color: black;
}
.featured a {
  text-decoration: none;
  color: green;
}
.arrowt{
  margin: 10px;
}

@media screen and (max-width: 1024px){

}

@media screen and (max-width: 800px) {
  .featured {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    line-height: 10px;
    margin-bottom: 1rem;
    margin-top: -15rem;
    height: 30vh;
    width: 100%;
  }
  .featured h1{
    font-size: 20px;
  }
  .featured h4{
    font-size: 15px;
  }
  .feat-btn{
    border-radius: 5px;
    padding: 8px 15px;
    border: none;
    color: #183a1d;
    cursor: pointer;
  }
  .arrowt{
    margin: 5px;
  }
}