.hero-kibaoni{
    text-align: center;
    height: 100vh;
    background-image:  linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(./kibaoni.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.description-raila{
    width: 600px;
    position: absolute;
    z-index: 999;
    color: aliceblue;
    top: 30%;
    left: 20%;
}

.description h1{
    font-size: 46px;
}

.description p{
    font-size: 22px;
}

.carousel-boult{
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-boult span{
   width: 45px;
   height: 12px;
   background-color: rgb(179, 178, 176);
   margin-left: 10px;
   border-radius: 10px;
   cursor: pointer;
   box-shadow: 3px 2px 2px rgba(73, 72, 72, 0.338);
}

.transparent-background{
    max-width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 998;
    top: 0;
    left: 0;
    /* background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(166, 255, 0)); */
}

.Hero{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 5%;  
    padding: 40px;
    margin-top: 40px
}
.Hero h3{
    text-align: center;
    margin-top: 100px;
}
h3{
    text-align: center;
    margin-top: 100px;
}

.Hero p{
    text-align: center;
}
.hero-img{
    width: 90%;
    max-height: 1200px;
}

.me{
    margin: 0;
    padding: 40px;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 5%;
}

.about-content h1{
    margin-bottom: 0;
    color: green;
}

.A-img{
    width: 100%;
    max-height: 1200px; 
}
.about-content h3{
    margin-bottom: 0.5rem;
    color: green;
}

.program_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

h2{
    text-align: center;
}

.program_container > div {
    background: #0d7a0b;
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.program_container > div:hover{
    background: transparent;
    border-color: #093a08;
    cursor: default;
}

.program_container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: #fff;
}

.program_content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* row-gap: 2rem; */
}

.program_details{
    display: flex;
    gap: 1rem;
}

.program_details-icon{
    margin-top: 6px;
    color: yellowgreen;
}

.contact-form{
    margin-top: 100px;
    width: 50%;
    color: black
}
input {
    background-color: #093a08
}
input, textarea{
    color: black;
}

input, placeholder{
    color: #fff;
}
.contact-btn{
    width: 20%;
    border-radius: 5px;
    padding: 2px;
}

.give{
    margin-top: 150px;
   
}

.give h1{
    height: 100px;
    justify-content: center;
}

.choir{
    margin-top: 150px;
}
.vid1-btn{
    width: 20%;
    border-radius: 5px;
    padding: 2px;
    margin-top: 20px;
    margin-left: 30rem; 
    font-size: 22px;
}
.give{
     color: black;
}

.pays{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2rem;
}


@media all and (max-width: 1440px){
    .about-content h1{
        margin-bottom: 0;
        
    }
    
    .about-content h3{
        margin-bottom: 0.5rem;
        color: green;
    }
    
} 

@media screen and (max-width: 1024px){
    .program_container{
        grid-template-columns: 1fr;
    }

    .program_container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .program_container{
     padding: 1rem;
    }
}


@media all and (max-width: 600px){
    .hero{
        height: 50vh;
    }

    .hero-kibaoni{
        position: relative;
        z-index: 995;
    }
    .description{
        width: fit-content;
    }
    
    .description h1{
        font-size: 22px;
    }
    
    .description p{
        font-size: 11px;
    }
    .carousel-boult span{
        display: none;
    }
    .Hero{
        grid-template-columns: 1fr;
    }
    body{
        padding: 0;
    }
    
        .me{
            width: 100%;
            width: var(--container-width-sm);
        }
        .about-content p{
            margin-bottom: 0;
            font-size: 11px;
        }
    
        .about-content h1{
            margin-top: 4rem;
            margin-bottom: 0;
            font-size: 12px;
            
        }
        
        .about-content h3{
            margin-bottom: 0.5rem;
            font-size: 8px;
            
        }
      
        .abt{
            margin-top: 13rem;
        }

        .about_container{
            grid-template-columns: 1fr;
            gap: 1rem;
        }
    
        .program_container > div{
            width: 100%;
            padding: 2rem 1rem;
        }
        .contact-btn{
            width: 50%;
        }
        .contact-form{
            margin-top: 100px;
            width: 100%;
        }
        .iframe{
            width: 20%;
        }

        .vid1-btn{
            width: 20%;
            padding: 1px;
            margin-top: 10px;
            margin-left: 15rem; 
            font-size: 12px;
        }
}