/* CardItem.css */
.c-item {
  background-color: #fff;
  /* border-radius: 10px; */
  overflow: hidden;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin-top: 20px; 
}

.c-item:hover {
  transform: scale(1.05);
}

.c-img {
  width: 100%;
  max-height: 50vh;
  object-fit: cover;
}

.card-content {
  padding: 1rem;
  text-align: center;
}

.card-label {
  font-size: 1.2rem;
  color: #333;
}

.card-text {
  margin-top: 10px;
  color: black;
  font-weight: bolder;
}


/* ThreeCards.css */
.cs {
  padding: 2rem;
  background: #f8f8f8;
  text-align: center;
}

.kard-head {
  color: #183a1d;
  text-align: left;
  font-family: sans-serif;
}

.c-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.c-items li {
  margin: 2rem;
  flex: 0 0 calc(30% - 4rem); /* Adjust the width as needed */
}



@media screen and (max-width: 650px){
  .c-items{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .cs{
    max-width: 100%;
  }
}