.about_container {
    width: 100%;
}

.about_me-image:hover{
    transform: rotate(0);
}

.about_cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about_card{
    background: #137411;
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 1rem;
    text-align: center;
    transition: var(--transition);
}
.about_card  ul li{
    /* list-style: armenian; */
    text-align: left;
    margin-left: 60px;
    line-height: 25px;
}

.about_card:hover{
    background: transparent;
    border-color: #093a08;
    cursor: default;
}

.about-icon {
    color: #137411;
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about_card h5{
    font-size: 0.95rem;
}

about_card small{
    font-size: 0.7rem;
    color: #137411;
}

.about_content p{
    margin: 1rem 0 0.6rem;
    font-size: 1.0rem;
}

.core{
    margin-top: 30px;
}

p{
    line-height: 30px;
}

.who-we-are{
    margin-top: 50px;
    line-height: 15px;
}



@media screen and (max-width: 600px){
    .about_container{
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about_cards{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1.5rem;
    }
    
    .about_card{
        background: #137411;
        border: 1px solid transparent;
        border-radius: 1rem;
        padding: 1rem;
        text-align: center;
        transition: var(--transition);
    }
    .about_card  ul li{
        /* list-style: armenian; */
        text-align: left;
        margin-left: 30px;
        line-height: 15px;
    }
    
    .about-icon {
        color: #093a08;
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    
    .about_card h5{
        font-size: 0.8rem;
    }
    
    about_card small{
        font-size: 0.3rem;
        color: #137411;
    }
    
    .about_content p{
        margin: 1rem 0 0.6rem;
        font-size: 0.8rem;
    }
    
    .core{
        margin-top: 15px;
    }
    
    p{
        line-height: 20px;
    }
    
    .who-we-are{
        margin-top: 25px;
        line-height: 15px;
    }
}

@media screen and (max-width: 1024px){
    .about_container{
        
    }
}