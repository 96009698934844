.testimonials {
    background-color: #183a1d;
  }
  .main {
    margin-top: 10%;
  }
  
  .mySwiper {
    width: 80%;
  }
  
  .swiper-slide {
    width: 200px;
    background-color: transparent;
    border-radius: 10px;
    background-size: cover;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
    color: #ffffff;
  }
  .testimonials-profile-circle {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .testimonial-avatar {
    width: 100px;
    height: 100px;
    border: 3px solid #9acd32;
    border-radius: 50%;
  }
  .review-by {
    color: #9acd32;
    font-size: 18px;
  }
  .head {
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    font-weight: 500;
    font-family: "Courier New", Courier, monospace;
  }
  .head-p {
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 500;
    font-family: "Courier New", Courier, monospace;
  }
  .swiper-pagination-bullet-active {
    background-color: #ffffff !important;
    border: 2px solid #9acd32 !important;
    /* padding: 1px !important; */
    border-radius: 1px !important;
    /* padding-left: 5px !important; */
    width: 30px !important;
    height: 1px !important;
    /* padding-right: 5px !important; */
  }
  .swiper-pagination-bullet {
    background-color: #ffffff !important;
    border: 2px solid #9acd32 !important;
    /* padding: 1px !important; */
    border-radius: 1px !important;
    /* padding-left: 5px !important; */
    width: 30px !important;
    height: 1px !important;
    /* padding-right: 5px !important; */
  }